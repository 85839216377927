@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: 'Courier New', Courier, monospace;
    -webkit-font-smoothing: none;
    -moz-osx-font-smoothing: none;
  }
}

.pixel-shadow {
  text-shadow: 
    2px 2px 0 #692424,
    4px 4px 0 #461818;
}